@font-face {
  font-family: 'Futura Heavy';
  font-display: swap;
  src: url('/fonts/373314_0_0.woff2') format('woff2');

}

@font-face {
  font-family: 'FuturaPT-Light';
  font-display: swap;
  src: url('/fonts/light.woff2') format('woff2');

}

@mixin sm-screen {
  @media (min-width: 576px) {
    @content;
  }
}


@mixin md-screen {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg-screen {
  @media (min-width: 992px) {
    @content;
  }
}


@mixin ex-screen {
  @media (min-width: 1400px) {
    @content;
  }
}




.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
}

html, body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}


.video-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  opacity: 0.05;
  display: none;
}


.video-wrapper.show {
   display: block;
}

.video-overlay {

  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.loading-screen {
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5000;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}


.loading-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  top:0;
  left: 0;
  .cls-1{fill:none;}.cls-2{isolation:isolate;}.cls-3{fill:#ffc82e;}.cls-4{clip-path:url(#clip-path);}.cls-5{opacity:0.5;mix-blend-mode:overlay;}.cls-6{fill:#fff;}

  .cls-3 {
    transition: 0s;
    animation: alter 1s infinite;

  }
  .cls-6 {
    transition: 0s;

    animation: alter-re 1s infinite;
  }
}

@keyframes alter {
  0%   { fill: #ffc82e; }
  50% { fill: #f6dc97; }
  100%   { fill: #ffc82e; }
}

@keyframes alter-re {
  0%   { fill: #ffffff; }
  50% { fill: #ffc82e; }
 100%   { fill: #ffffff; }

}

.loading-logo {
  width: 100%;
}

.loading-percentage  {
  position: absolute;
  z-index: 5005;
  font-family: bello-pro, sans-serif;
  font-weight: 400;
  color: #ffc82e;
  font-size:1.7vw;

}

.lower-third {
  position: absolute;
  width: 20%;
  right: 0;
  bottom: 0;

}

.lower-third-logo {

  width: 100%;
}

.loading-info {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.level-up-screen {
  position: absolute;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  z-index: 5002;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.level-up-screen-bg {
  height: 500vh;
  width: 100%;
  background-size: 100vw, 100vh !important;
  position: absolute;
  background-repeat: repeat-y !important;
  opacity: 0.4;
  z-index: 5002;
}

.level-up-screen-static {
  position: absolute;
  z-index: 5001;
  width: 100vw;
  height: 100vh;
  background-size: cover !important;
  opacity: 0.1;
}

.level-up-logo {
  width: 70%;
  position: absolute;
  z-index: 5003;
  transform: scale(0);
}



.logo {
  .cls-1{fill:none;}.cls-2{isolation:isolate;}.cls-3{clip-path:url(#clip-path);}.cls-4,.cls-6{opacity:0.5;}.cls-4{mix-blend-mode:overlay;}.cls-10,.cls-11,.cls-14,.cls-5{fill:#fff;}.cls-7{fill:#ff3154;}.cls-8{font-size:126.13px;font-family:BelloScriptPro-Regular, Bello Script Pro;}.cls-12,.cls-8{fill:#ffc82e;}.cls-11,.cls-9{letter-spacing:0em;}.cls-10{letter-spacing:0em;}.cls-12{font-size:29.54px;font-family:CooperBlackStd, Cooper Std;}.cls-13{letter-spacing:-0.08em;}.cls-14{letter-spacing:-0.04em;}
}

.loading-circle {
  position: absolute;
  z-index: 5004;
width: 20vw;
height: 20vw;
}

.hell-overlay {
  position: absolute;
  z-index: 5001;
  width: 100%;
  height: 100%;
  background: rgb(131,58,180);
  background: linear-gradient(169deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
  opacity: 0.2;
}

.music-note { 
  position: absolute;
  z-index: 8000;
opacity: 0;
  bottom: 20px;
  left: 20px;

  color: #ffffff;
  width: 20vw;
  font-size: 1vw;
  padding: 0.5vw;
  img {
    width: 100%;
  }
}

.action-note {
  position: absolute;
  z-index: 8000;
  opacity: 1;
  bottom: 20px;
  left: 20px;
    &:hover {
      cursor: pointer;
    }
  color: #ffffff;
  width: 20vw;
  font-size: 1vw;
  padding: 0.5vw;
  img {
    width: 100%;
  }
}

.stats  {
  position: fixed;
  z-index: 10000;
  bottom:0;
  left: 0;
  color: #ffffff;
  background: #000000;

}

.landing-video-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  display: block;
}

.landing-video {

  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}



.landing-overlay  {
  background: rgba(#000000, 0.6);
  position: absolute;
  height: 100vh;
  width: 100vw;
  top:0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-main {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  align-items: center;
  justify-content: center;
  opacity: 0;
  padding: 40px;

}

.landing-logo {
  width: 100%;
  max-width: 1000px;
  margin-bottom: -10%;

}

.landing-warning {
  font-size: 30px;
  color: #ffffff;
  margin-bottom: 20px;
}

.landing-select {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.landing-div {
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-family: FuturaPT-Light, sans-serif;
  font-size: 55px;
}

.landing-item {
  width: 100%;
}

.landing-item-title {
  font-size: 40px;
  font-family: "Futura Heavy", sans-serif;
  color: #ffffff;
  margin-bottom: 10px;
  @include md-screen {
    font-size: 50px;
  }

  @include lg-screen {
    font-size: 75px;
  }


}

.landing-item-subtitle {
  font-size: 25px;
  font-family: FuturaPT-Light, sans-serif;
  color: #ffffff;
}

.landing-button {
  font-size: 30px;
  background: none;
  border-radius: 20px;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-family: FuturaPT-Light, sans-serif;
  padding: 0px 50px 2px 50px;
  margin-top: 30px;
  transition: 0.4s;
  &:hover {
    background: #ffffff;
    color: black;
    cursor: pointer;
  }
}

.start-button {
  width: 8vw;
  height: 8vw;

  background-color: #ffffff;
  border-radius: 50%;
  border: 5px solid #ffc82e;
  position: relative;
  color: #ffc82e;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5vw;
  font-family: bello-pro, sans-serif;
  margin-top: -16vw;
  transition: box-shadow 1s, color 1s, background-color 1s;
  -webkit-box-shadow: -2px 1px 19px 1px rgba(0,0,0,0.27);
  box-shadow: -2px 1px 19px 1px rgba(0,0,0,0.27);
  &:hover {
    cursor: pointer;
    background-color: #ffc82e;
    color: #ffffff;
    -webkit-box-shadow: -2px 1px 19px 8px rgba(0,0,0,0.37);
    box-shadow: -2px 1px 19px 8px rgba(0,0,0,0.37);

  }

}

.loading-screen {
  flex-direction: column;
}

.mobile-screen {
  display: none;
  @media (max-width: 1024px) {
  display: flex;
  }
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5000;
  height: 100vh;
  width: 100vw;
  background-color: #ffc82e;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 3vw;
  text-align: center;
}